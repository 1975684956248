import { CheckPlusSafeMissControllerService } from '@/__generated__/CommonApi';
import { isBrowser, openPopup } from '@/helpers/BrowserHelper';
import React, { ReactElement, useEffect, useRef, useState, VFC } from 'react';
import { createPortal } from 'react-dom';

export interface NiceAuthResult {
  type: 'nice-auth';
  resultCode: '0' | string;
  // 성공시
  mobile?: string;
  name?: string;
  donorId?: string;
  giftId?: string;
  gender?: string;
  userId?: any;
  birthDay?: string;
  // 실패시
  resultMessage?: string;
}
interface NiceAuthenticationMissProps {
  onSuccess?: (result: NiceAuthResult) => void;
  onError?: (message: string) => void;
  renderSubmitButton: (props: { onClick: () => void }) => ReactElement;
}

const NiceAuthenticationMissForm: VFC<NiceAuthenticationMissProps> = ({
  renderSubmitButton,
  onSuccess,
  onError,
}) => {
  const [encData, setEncData] = useState(``);
  const formRef = useRef<HTMLFormElement>(null);

  const start = async () => {
    const {
      data: { sEncData },
    } = await CheckPlusSafeMissControllerService.setNiceEncDataUsingGet();
    setEncData(sEncData);
  };

  // encData가 변경되면 팝업 열기
  useEffect(() => {
    if (encData) {
      openPopup(
        ``,
        `popupChk`,
        `width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no`,
      );
      formRef.current?.submit();
    }
  }, [encData]);

  // postMessage 리스너 등록
  useEffect(() => {
    const callback = (e: MessageEvent<NiceAuthResult>) => {
      const { type, resultCode, resultMessage } = e.data;
      // 해당되는 이벤트가 아니라면 무시
      if (type !== `nice-auth`) {
        return;
      }
      // 성공시
      if (resultCode === `success`) {
        onSuccess?.(e.data);
      } 
      else if (resultCode === `failed`) {
        onSuccess?.(e.data);
      }
      // 실패시
      else if (resultMessage) {
        onError?.(resultMessage || `본인인증 도중 에러가 발생했습니다.`);
      }
    };
    window.addEventListener(`message`, callback, false);
    return () => window.removeEventListener(`message`, callback);
  }, [onError, onSuccess]);

  if (!isBrowser) {
    return null;
  }

  return (
    <>
      {renderSubmitButton({ onClick: start })}
      {createPortal(
        <form
          ref={formRef}
          name="parentForm"
          method="post"
          action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
          target="popupChk"
        >
          <input type="hidden" name="m" value="checkplusService" />
          <input type="hidden" name="EncodeData" value={encData} />
        </form>,
        document.body,
      )}
    </>
  );
};

export default NiceAuthenticationMissForm;
